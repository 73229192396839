var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SalaryInfoDetail-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "薪资信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      label: "员工编号",
                      text: _vm.form.jobNum || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "员工姓名",
                      text: _vm.basicInfo.userName || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "证件类型",
                      text: _vm.basicInfo.cardType || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "证件号码",
                      text: _vm.basicInfo.cardNum || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "联系电话",
                      text: _vm.basicInfo.mobileNum || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "所属公司",
                      text: _vm.basicInfo.tenantName || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "部门",
                      text: _vm.basicInfo.orgName || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "岗位",
                      text: _vm.basicInfo.adminDuty || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "职级",
                      text: _vm.basicInfo.jobRank || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "薪资日期",
                      text: _vm.form.fmonth || "--",
                      span: 24,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "薪资档级",
                      text: _vm.basicInfo.salaryGrade || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "基本工资",
                      text: _vm.salaryStandardInfo.baseSalary || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "绩效工资",
                      text: _vm.salaryStandardInfo.performanceSalary || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "社会保险",
                      text: _vm.salaryStandardInfo.socialInsurance || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "社险补贴",
                      text:
                        _vm.salaryStandardInfo.socialInsuranceSubsidy || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "公积金",
                      text: _vm.salaryStandardInfo.providentFund || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "公积金补贴",
                      text: _vm.salaryStandardInfo.providentFundSubsidy || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "通讯补贴",
                      text: _vm.salaryStandardInfo.communicationSubsidy || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "交通补贴",
                      text:
                        _vm.salaryStandardInfo.transportationSubsidy || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "餐贴",
                      text: _vm.salaryStandardInfo.mealSubsidy || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "汽油费补贴",
                      text: _vm.salaryStandardInfo.petrolSubsidy || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "岗位补贴",
                      text: _vm.salaryStandardInfo.jobRankSubsidy || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "其他补贴",
                      text: _vm.salaryStandardInfo.otherSubsidy || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "考核奖",
                      text: _vm.salaryStandardInfo.assessmentAward || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "高温费",
                      text: _vm.salaryStandardInfo.highTemperatureFee || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "加班费",
                      text: _vm.form.overtimeFee || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "请假费",
                      text: _vm.form.leaveFee || "--",
                      span: 8,
                      required: "",
                    },
                  }),
                  _c("col2-item", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "summary-salary" }, [
                      _c("span", [_vm._v("合计：" + _vm._s(_vm.form.total))]),
                    ]),
                  ]),
                  _c("col2-item", {
                    attrs: {
                      label: "社保扣款",
                      text: _vm.form.socialSecurityDeduction,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "公积金扣款",
                      text: _vm.form.providentFundDeduction,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "养老扣款",
                      text: _vm.form.pensionDeduction,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "生育扣款",
                      text: _vm.form.maternityDeduction,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "失业扣款",
                      text: _vm.form.unemploymentDeduction,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "工伤扣款",
                      text: _vm.form.workInjuryDeduction,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "医疗扣款",
                      text: _vm.form.medicalDeduction,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "个人抵扣税",
                      text: _vm.form.personalTaxDeduction,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "个税",
                      text: _vm.form.personalTax,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "其他扣款",
                      text: _vm.form.otherDeduction,
                      span: 8,
                    },
                  }),
                  _c("col2-item", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "summary-salary" }, [
                      _c("span", [
                        _vm._v("扣减：" + _vm._s(_vm.form.totalDeduction)),
                      ]),
                    ]),
                  ]),
                  _c("col2-item", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "summary-salary" }, [
                      _c("span", [
                        _vm._v("实发：" + _vm._s(_vm.form.actualPay)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }