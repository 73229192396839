<template>
  <div class="SalaryInfoDetail-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      @update="update"
    >
      <col2-detail>
        <col2-block title="薪资信息"> 
          <col2-item label="员工编号" :text="form.jobNum||'--'" :span="8" required/>     
          <col2-item label="员工姓名" :text="basicInfo.userName||'--'" :span="8" required/>
          <col2-item label="证件类型" :text="basicInfo.cardType||'--'" :span="8" required/>         
          <col2-item label="证件号码" :text="basicInfo.cardNum||'--'" :span="8" required/>
          <col2-item label="联系电话" :text="basicInfo.mobileNum||'--'" :span="8" required/>   
          <col2-item label="所属公司" :text="basicInfo.tenantName||'--'" :span="8" required/>            
          <col2-item label="部门" :text="basicInfo.orgName||'--'" :span="8" required/>
          <col2-item label="岗位" :text="basicInfo.adminDuty||'--'" :span="8" required/> 
          <col2-item label="职级" :text="basicInfo.jobRank||'--'" :span="8" required/>               
          <col2-item label="薪资日期" :text="form.fmonth||'--'" :span="24" required/>              
            <col2-item label="薪资档级" :text="basicInfo.salaryGrade||'--'" :span="8" required/>     
            <col2-item label="基本工资" :text="salaryStandardInfo.baseSalary||'--'" :span="8" required/>
            <col2-item label="绩效工资" :text="salaryStandardInfo.performanceSalary||'--'" :span="8" required/>                    
            <col2-item label="社会保险" :text="salaryStandardInfo.socialInsurance||'--'" :span="8" required/>
            <col2-item label="社险补贴" :text="salaryStandardInfo.socialInsuranceSubsidy||'--'" :span="8" required/> 
            <col2-item label="公积金" :text="salaryStandardInfo.providentFund||'--'" :span="8" required/>
            <col2-item label="公积金补贴" :text="salaryStandardInfo.providentFundSubsidy||'--'" :span="8" required/>
            <col2-item label="通讯补贴" :text="salaryStandardInfo.communicationSubsidy||'--'" :span="8" required/> 
            <col2-item label="交通补贴" :text="salaryStandardInfo.transportationSubsidy||'--'" :span="8" required/> 
            <col2-item label="餐贴" :text="salaryStandardInfo.mealSubsidy||'--'" :span="8" required/>
            <col2-item label="汽油费补贴" :text="salaryStandardInfo.petrolSubsidy||'--'" :span="8" required/> 
            <col2-item label="岗位补贴" :text="salaryStandardInfo.jobRankSubsidy||'--'" :span="8" required/>
            <col2-item label="其他补贴" :text="salaryStandardInfo.otherSubsidy||'--'" :span="8" required/>
            <col2-item label="考核奖" :text="salaryStandardInfo.assessmentAward||'--'" :span="8" required/> 
            <col2-item label="高温费" :text="salaryStandardInfo.highTemperatureFee||'--'" :span="8" required/>
            <col2-item label="加班费" :text="form.overtimeFee||'--'" :span="8" required/> 
            <col2-item label="请假费" :text="form.leaveFee||'--'" :span="8" required/>                          
         
          <col2-item :span="24">
          <div class="summary-salary">
            <span>合计：{{form.total}}</span>
          </div>
          </col2-item>
          <col2-item label="社保扣款" :text="form.socialSecurityDeduction" :span="8"></col2-item>
          <col2-item label="公积金扣款" :text="form.providentFundDeduction" :span="8"></col2-item>
          <col2-item label="养老扣款" :text="form.pensionDeduction" :span="8"></col2-item>
          <col2-item label="生育扣款" :text="form.maternityDeduction" :span="8"></col2-item>
          <col2-item label="失业扣款" :text="form.unemploymentDeduction" :span="8"></col2-item>
          <col2-item label="工伤扣款" :text="form.workInjuryDeduction" :span="8"></col2-item>
          <col2-item label="医疗扣款" :text="form.medicalDeduction" :span="8"></col2-item>
          <col2-item label="个人抵扣税" :text="form.personalTaxDeduction" :span="8"></col2-item>
          <col2-item label="个税" :text="form.personalTax" :span="8"></col2-item>
          <col2-item label="其他扣款" :text="form.otherDeduction" :span="8"></col2-item>       
       
          <col2-item :span="24">
          <div class="summary-salary">
            <span>扣减：{{form.totalDeduction}}</span>
          </div>
          </col2-item>
          <col2-item :span="24">
          <div class="summary-salary">
            <span>实发：{{form.actualPay}}</span>
          </div>
          </col2-item>
          
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryURL,getBasicInfoURL,getSalaryStandardByTenantAndGradeURL} from "./api";
import { Col2Detail, Col2Block, Col2Item } from "components/bussiness";
import { vPreview } from "@/components/control";

export default {
  name: "GoodsOutDoorForm",
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    vPreview,
  },
  data() {
    return {
      width: 182,
      form: {},
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: "",
      },
      basicInfo: {},
      salaryStandardInfo: {}
    };
  },
  mounted() {
    const { id } = this.$route.query;
    this.$setBreadcrumb("详情");
    this.$refs.formPanel.getData({ id });
  },
  methods: {
    update(data) {
      this.form = data;
      this.getBasicInfoList()
    },
    getSalaryStandard() {
      // 获取用户基础信息
      let params = {
        tenantId: this.basicInfo.tenantId,
        salaryGrade: this.basicInfo.salaryGrade
      };
      this.$axios.get(getSalaryStandardByTenantAndGradeURL, { params: params }).then((res) => {
        if (res.status === 100 && res.data) {
          this.salaryStandardInfo = res.data;
        }
      });
    },
    getBasicInfoList() {
      // 获取用户基础信息
      let params = {
        keyWord: this.form.jobNum
      };
      this.$axios.get(getBasicInfoURL, { params: params }).then((res) => {
        if (res.status === 100 && res.data) {
          let b = res.data.filter((item)=>{return item.jobNum == this.form.jobNum })
          this.basicInfo = b[0]
          this.getSalaryStandard()
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.SalaryInfoDetail-wrapper {
  .summary-salary{
    text-align: right;
    padding-bottom: 20px;
  }
}
</style>
