// 列表
const getListURL = `${API_CONFIG.butlerBaseURL}staffManagement/getStaffMonthSalaryList`
// 导出
const exportUrl = `${API_CONFIG.butlerBaseURL}staffManagement/exportStaffSalaryList`
// 新增
const saveOutURL = `${API_CONFIG.shopBaseURL}staffManagement/addStaffMonthSalary`
// 查询
const queryURL = `${API_CONFIG.shopBaseURL}staffManagement/getStaffMonthSalaryDetail`
// 编辑
const updateOutURL = `${API_CONFIG.shopBaseURL}staffManagement/updateStaffMonthSalary`
// 开启/关闭
const closeURL = `${API_CONFIG.shopBaseURL}staffManagement/updateStaffMonthSalaryStatus`
// 下拉选项数据
const getInitInfoURL = `${API_CONFIG.butlerBaseURL}staffManagement/getInitCodeWordListBase`
// 员工基础信息
const getBasicInfoURL = `${API_CONFIG.butlerBaseURL}staffManagement/getShortStaffInfoList`
// 查询薪资标准
const getSalaryStandardByTenantAndGradeURL = `${API_CONFIG.butlerBaseURL}salaryStandard/getSalaryStandardByTenantAndGrade`
// 获取组织树
const getEnterOrgTreeListURL = `${API_CONFIG.butlerBaseURL}enterpriseOrgInfo/getEnterOrgTreeList`
export {
  getListURL,
  exportUrl,
  queryURL,
  saveOutURL,
  updateOutURL,
  closeURL,
  getInitInfoURL,
  getBasicInfoURL,
  getEnterOrgTreeListURL,
  getSalaryStandardByTenantAndGradeURL
}
